import React, { useState } from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';
import { Link } from 'gatsby';
import { VscClose } from '@react-icons/all-files/vsc/VscClose';

const TechnologyCardStyled = styled.div`
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  min-width: 100%;
  min-height: 540px;
  border-radius: 8px;
  color: var(--white);
  text-align: center;
  padding: 7rem 5rem;
  transition: 500ms ease-in-out;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  &.open {
    background-size: 4000%;
    background: var(--black);
    background-image: linear-gradient(
      to bottom,
      var(--black),
      var(--black)
    ) !important;
    background-position: center top;
  }
  .narrow {
    max-width: 400px;
    margin: 2rem auto 0;
  }
  .description {
    background: var(--black);
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    padding: 4rem 3rem;
    transition: 200ms ease-in;
    overflow-y: auto;
    span {
      position: relative;
      display: grid;
      align-items: center;
      height: 100%;
    }
    svg {
      position: absolute;
      top: 1rem;
      right: 1rem;
      font-size: 3rem;
    }
    a.boa-guarantee {
      color: var(--white);
    }
    p {
      max-width: calc(var(--wrapper) / 2);
      margin-left: auto;
      margin-right: auto;
    }
    @media (max-width: 1023px) {
      padding: 2rem;
    }
  }
  .description.reveal {
    opacity: 1;
  }
  .cta {
    display: inline-block;
    cursor: pointer;
    color: var(--watermelon);
    padding: 2rem;
    line-height: 150%;
  }
`;

export default function TechnologyCard(props) {
  const [descState, toggleDescription] = useState(false);
  function toggleDesc() {
    toggleDescription(!descState);
  }
  return (
    <TechnologyCardStyled
      style={{
        backgroundImage: `linear-gradient(to bottom, var(--black), rgba(0,0,0,0.3)),url(${
          props.backgroundImage ? props.backgroundImage : ''
        })`,
      }}
      onClick={toggleDesc}
      className={props.dataItem === 4 ? 'full-width' : ''}
    >
      <h4>{props.title}</h4>
      <p className="narrow">{props.subTitle ? props.subTitle : ''}</p>
      <div className={descState ? 'description reveal' : 'description'}>
        <VscClose />
        <span>
          <div>
            <h4>{props.title}</h4>
            <p>{props.description ? props.description : ''}</p>
            <p>
              {props.title.includes('BOA') ? (
                <a
                  href="https://www.boafit.com/en-us/support/warranty"
                  target="_blank"
                  rel="noreferrer"
                  className="boa-guarantee"
                >
                  Learn more about the BOA Guarantee
                </a>
              ) : (
                ''
              )}
            </p>
          </div>
        </span>
      </div>
      <span className="cta" role="button" tabIndex={0}>
        {props.callToAction ? props.callToAction : ''}
      </span>
    </TechnologyCardStyled>
  );
}
